<script>
import SFTPHeader from "@/components/SFTP/SFTPHeader.vue";

export default {
  name: "LocalHost",
  components: {SFTPHeader}
}
</script>

<template>
  <div id="LocalHost">
    <SFTPHeader icon="SFTPLocalHostIcon.svg"/>
  </div>
</template>

<style scoped>
#LocalHost {
  width: 50%;
  height: 100%;
  float: left;
}
</style>