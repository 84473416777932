<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PageHeaderDroplist"
})
</script>

<template>
  <button class="PageHeaderDroplist"></button>
</template>

<style scoped>
  .PageHeaderDroplist {
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.1);
    width: 22px;
    height: 22px;
    border: transparent;
    background: url("../../../assets/icons/PageHeaderDroplist.svg") no-repeat;
    margin-right: 10px;
  }
</style>