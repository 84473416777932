<script setup>

</script>

<template>
  <div id="RDP">

  </div>
</template>

<style scoped>
#RDP {
  width: 1000px;
  height: 500px;
  background: blue;
}
</style>