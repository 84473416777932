<script setup>

</script>

<template>
  <div id="FTP">

  </div>
</template>

<style scoped>
#FTP {
  width: 1000px;
  height: 500px;
  background: coral;
}
</style>