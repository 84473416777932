<script>
import {defineComponent} from 'vue'
export default defineComponent({
  name: "PageHeaderConnectionInput"
})
</script>

<template>
  <input id="PageHeaderConnectionInput">
</template>

<style scoped>
#PageHeaderConnectionInput {
  width: 85%;
  height: 90%;
  margin-right: 2%;
  border: transparent;
  background: transparent;
  font-family: Inter;
  font-size: x-large;
  color: #E6E8EF;
}
#PageHeaderConnectionInput:focus {
  outline: none;
}
</style>