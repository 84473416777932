<script>
import SFTPHeader from "@/components/SFTP/SFTPHeader.vue";

export default {
  name: "RemoteHost",
  components: {SFTPHeader}
}
</script>

<template>
  <div id="RemoteHost">
    <SFTPHeader icon="SFTPRemoteHostIcon.svg"/>
  </div>
</template>

<style scoped>
#RemoteHost {
  width: 50%;
  height: 100%;
  float: left;
}
</style>