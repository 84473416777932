<script setup>

</script>

<template>
  <div id="ConsolePage">

  </div>
</template>

<style scoped>
#ConsolePage {
  width: 1000px;
  height: 500px;
  background: red;
}
</style>