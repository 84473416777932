<script>
export default {
  name: "SFTPHeader",
  props: {
    icon: String
  }
}
</script>

<template>
  <div class="SFTPHeader">
    <div class="SFTPHeaderIcon">
      <img :src="require(`../../assets/icons/${icon}`)">
    </div>
  </div>
</template>

<style scoped>
.SFTPHeader {
  background: #2B323E;
  height: 96px;
  padding-top: 21px;
  border-bottom: 1px #1D222A solid;
  border-left: 1px #1D222A solid;
}
.SFTPHeaderIcon {
  background: #065471;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 21px;
}
</style>