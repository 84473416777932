<script>

import LocalHost from "@/components/SFTP/LocalHost.vue";
import RemoteHost from "@/components/SFTP/RemoteHost.vue";

export default {
  name: "SFTP",
  components: {
    LocalHost, RemoteHost
  },
  data() {
    return {
      windowMin: window.innerWidth <= 900,
      adaptiveWidth: this.windowMin ? window.innerWidth -  123 : window.innerWidth -  323
    }
  },
  computed: {
    getMargin() {
      return this.windowMin ? 123 : 323
    }
  },
  methods: {
    handleResize() {
      window.innerWidth <= 900 ? this.windowMin = true : this.windowMin = false
      this.windowMin ? this.adaptiveWidth = window.innerWidth -  123 : this.adaptiveWidth = window.innerWidth -  323
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }
}
</script>

<template>
  <div id="SFTP" :style="{ marginLeft: getMargin + 'px', width: getWidth + 'px' }">
    <LocalHost />
    <RemoteHost />
  </div>
</template>

<style scoped>
#SFTP {
  display: block;
  height: 100%;
}
</style>