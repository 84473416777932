<script>
import PageHeaderConnection from "@/components/HostsPage/PageHeader/PageHeaderConnection.vue";
import PageHeaderButton from "@/components/HostsPage/PageHeader/PageHeaderButton.vue";
import PageHeaderDroplist from "@/components/HostsPage/PageHeader/PageHeaderDroplist.vue";

let headerMargin = window.innerWidth * 0.01
let headerWidth = window.innerWidth - 123 - headerMargin
let headerPadding = 123
if (window.innerWidth > 900) {
  headerWidth = window.innerWidth - 323 - headerMargin
  headerPadding = 323
}

export default {
  name: "PageHeader",
  components: {
    PageHeaderDroplist,
    PageHeaderButton,
    PageHeaderConnection
  },
  methods: {
    updateWindowWidth() {
      if (window.innerWidth > 900) {
        this.headerWidth = window.innerWidth - 323 - headerMargin;
        this.headerPadding = 323
      } else {
        this.headerWidth = window.innerWidth - 123 - headerMargin;
        this.headerPadding = 123
      }
    },
  },
  created() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  data() {
    return {
      headerWidth: headerWidth,
      headerMargin: headerMargin,
      headerPadding: headerPadding,
    };
  },
}
</script>

<template>
  <div id="PageHeader" :style="{
  width: headerWidth + 'px',
  marginLeft: headerMargin + 'px',
  paddingLeft: headerPadding + 'px'}">
    <PageHeaderConnection id="PageHeaderConnection"/>
    <div class="PageHeaderButtons">
      <PageHeaderButton style="border-top-right-radius: 0; border-bottom-right-radius: 0; margin-right: 2px" msg="Новый Хост"/>
      <PageHeaderDroplist style="border-top-left-radius: 0; border-bottom-left-radius: 0"/>
      <PageHeaderButton msg="Терминал"/>
      <PageHeaderButton msg="Серийный Порт"/>
    </div>
  </div>
</template>

<style scoped>
  #PageHeader {
    background: #2B323E;
    height: 117px;
    z-index: 2;
    display: grid;
    align-items: center;
  }
  .PageHeaderButtons {
    display: flex;
    margin-left: 1%;
  }
</style>