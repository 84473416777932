<script>
import PageHeader from "@/components/HostsPage/PageHeader/PageHeader.vue";

export default {
  name: "HostsPage",
  components: {PageHeader}
}
</script>

<template>
  <PageHeader />
  <div id="HostsPage">

  </div>
</template>

<style scoped>
#HostsPage {

}
</style>