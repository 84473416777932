<script>

import PageHeaderConnectionButton from "@/components/HostsPage/PageHeader/PageHeaderConnectionButton.vue";
import PageHeaderConnectionInput from "@/components/HostsPage/PageHeader/PageHeaderConnectionInput.vue";

let margin = window.innerWidth * 0.1

export default {
  name: "PageHeaderConnection",
  components: {PageHeaderConnectionInput, PageHeaderConnectionButton},
  methods: {
    updateMargin() {
      this.margin = window.innerWidth * 0.1;
    },
  },
  created() {
    window.addEventListener('resize', this.updateMargin);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateMargin);
  },
  data() {
    return {
      margin: margin,
    };
  },
}
</script>

<template>
  <div id="PageHeaderConnection" :style="{ marginLeft: margin, marginRight: margin }">
    <PageHeaderConnectionInput/>
    <PageHeaderConnectionButton/>
  </div>
</template>

<style scoped>
  #PageHeaderConnection {
    height: 52px;
    background: rgba(0, 154, 224, 0.15);
    width: 98%;
    border: #009AE0 1px solid;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>