<script>
export default {
  name: "PageHeaderConnectionButton"
}
</script>

<template>
  <button id="PageHeaderConnectionButton">Соединить</button>
</template>

<style scoped>
  #PageHeaderConnectionButton {
    border-radius: 5px;
    background: rgb(0, 154, 224);
    color: rgb(255, 255, 255);
    border: transparent;
    font-family: Inter;
    width: 122px;
    height: 32px;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-align: left;
    text-transform: uppercase;
    float: right;
    margin-right: 10px;
  }
</style>