<template>
  <LeftBar :current-component="currentComponent" @component-changed="currentComponent = $event"/>
  <component :is="currentComponent"></component>
</template>

<script>

import LeftBar from "@/components/LeftBar/LeftBar.vue";
import HostsPage from "@/components/HostsPage/HostsPage.vue";
import FTP from "@/components/FTP/FTP.vue";
import SFTP from "@/components/SFTP/SFTP.vue";
import RDP from "@/components/RDP/RDP.vue";
import ConsolePage from "@/components/ConsolePage/ConsolePage.vue";

export default {
  name: 'App',
  components: {
    LeftBar,
    HostsPage,
    FTP,
    RDP,
    SFTP,
    ConsolePage
  },
  data() {
    return {
      currentComponent: ''
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #222833;
  height: 100vh;
  width: 100vw;
  display: grid;
}
@font-face {
  font-family: 'Andale-Mono';
  src: url('./assets/fonts/andalemono.ttf') format('truetype');
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}
@media (min-width: 900px) #LeftBar {

}
</style>
