<script>

import LeftBarItem from "@/components/LeftBar/LeftBarItem.vue";
import LeftBarHeaderBox from "@/components/LeftBar/LeftBarHeaderBox.vue";

let leftBarWidth = 323
if (window.innerWidth < 900) {
  leftBarWidth = 123
}

export default {
  components: {
    LeftBarItem,
    LeftBarHeaderBox
  },
  data() {
    return {
      leftBarWidth: leftBarWidth
    }
  },
  methods : {
    updateLeftBar () {
      let LeftBar = document.getElementById("LeftBar")
      let LeftBarHeaderBox = document.getElementById("LeftBarHeaderBox")
      if (window.innerWidth > 900) {
        LeftBar.style.width = "323px"
        LeftBarHeaderBox.style.width = "323px"
      } else {
        LeftBar.style.width = "123px"
        LeftBarHeaderBox.style.width = "123px"
      }
    },
    changeComponent(component) {
      this.$emit('component-changed', component);
    }
  },
  created() {
    window.addEventListener("resize", this.updateLeftBar)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateLeftBar)
  }
}
</script>

<template>
  <div id="LeftBar" :style="{ width: leftBarWidth + 'px' }">
    <LeftBarHeaderBox
        :style="{ width: leftBarWidth + 'px' }"
        id="LeftBarHeaderBox"
    />
    <LeftBarItem msg="Хосты" icon="hosts.svg" v-on:click="changeComponent('HostsPage')"></LeftBarItem>
    <LeftBarItem msg="SFTP" icon="sftp.svg" v-on:click="changeComponent('SFTP')"></LeftBarItem>
    <LeftBarItem msg="Консоль" icon="console.svg" v-on:click="changeComponent('ConsolePage')"></LeftBarItem>
    <LeftBarItem msg="FTP" icon="ftp.svg" v-on:click="changeComponent('FTP')"></LeftBarItem>
    <LeftBarItem msg="RDP" icon="rdp.svg" v-on:click="changeComponent('RDP')"></LeftBarItem>
  </div>
</template>

<style scoped>
  #LeftBar {
    height: 100vh;
    background: #2B323E;
    border-right: 1px #1D222A solid;
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
  }
</style>