<script>
export default {
  name: "LeftBarHeaderButtonSettings",
  props: {
    icon: String
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    backgroundImage() {
      return this.isHovered ? require(`@/assets/icons/${this.icon}Hover.svg`) : require(`@/assets/icons/${this.icon}.svg`);
    }
  }
}
</script>

<template>
  <button class="LeftBarHeaderButton"
          :style="{ backgroundImage: `url(${backgroundImage})`}"
          @mouseover="isHovered = true"
          @mouseleave="isHovered = false"></button>
</template>

<style scoped>
.LeftBarHeaderButton {
  width: 35px;
  height: 35px;
  border: transparent;
  background: transparent no-repeat;
}
</style>