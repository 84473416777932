<script>

import LeftBarHeaderButton from "@/components/LeftBar/LeftBarHeaderButton.vue";

export default {
  components: {
    LeftBarHeaderButton
  },
  methods : {
    updateLeftBar () {
      let LeftBarHeaderBox = document.getElementById("LeftBarHeaderBox")
      let Template = document.getElementById("template")
      let Hosts = document.getElementById("hosts")
      if (window.innerWidth < 900) {
        LeftBarHeaderBox.style.width = "123px"
        Template.style.display = 'none'
        Hosts.style.marginLeft = "22px"
        this.windowMin = true
      } else {
        LeftBarHeaderBox.style.width = "323px"
        Template.style.display = 'block'
        Hosts.style.marginLeft = "80px"
        this.windowMin = false
      }
    }
  },
  computed: {
    getMargin() {
      return this.windowMin ? 22 : 80
    },
    getDisplay() {
      return this.windowMin ? 'none' : 'block'
    }
  },
  data() {
    return {
      windowMin: window.innerWidth <= 900
    }
  },
  created() {
    window.addEventListener("resize", this.updateLeftBar)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateLeftBar)
  }
}
</script>

<template>
  <div id="LeftBarHeaderBox">
    <div style="border-bottom: transparent" id="template" :style="{ display: getDisplay }">Connect</div>
    <LeftBarHeaderButton id="hosts" icon="hostsLeftBar" :style="{ marginLeft: getMargin + 'px' }"/>
    <LeftBarHeaderButton id="settings" icon="settingsLeftBar"/>
  </div>
</template>

<style scoped>
  #LeftBarHeaderBox {
    color: white;
    font-size: xx-large;
    align-content: center;
    display: flex;
    border-bottom: transparent;
    height: 116px;
    border-bottom: solid #363E4D 1px;
    border-right: solid #363E4D 1px;
    z-index: 1;
    margin-bottom: 30px;
  }
  #template {
    margin-left: 23px;
    margin-top: 20px;
  }
  #hosts {
    margin-top: 20px;
  }
  #settings {
    margin-top: 20px;
    margin-left: 10px;
  }
</style>