<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PageHeaderButton",
  props: {
    msg: String
  },
})
</script>

<template>
  <button class="PageHeaderButton">{{msg}}</button>
</template>

<style scoped>
  .PageHeaderButton {
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.1);
    font-family: Inter;
    height: 22px;
    border: transparent;
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 1.5px;
    text-align: left;
    text-transform: uppercase;
    margin-right: 10px;
  }
</style>