<script>

let width = 242
let margin = 25
if (window.innerWidth < 900) {
  width = 84
  margin = 32
}

export default {
  name: "LeftBarItem",
  props: {
    msg: String,
    checked: Boolean,
    icon: String
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 900) {
        this.windowMin = false
        this.msgData = this.msg
      } else {
        this.windowMin = true
        this.msgData = ''
      }
    }
  },
  computed: {
    getMsg() {
      return this.windowMin ? '' : this.msg
    },
    getWidth() {
      return this.windowMin ? 84 : 242
    },
    getMargin() {
      return this.windowMin ? 32 : 25
    }
  },
  data() {
    return {
      msgData: this.msg,
      width: width,
      marginLeft: margin,
      windowMin: window.innerWidth <= 900
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }
}
</script>

<template>
  <div class="LeftBarItem"
       :style="{ width: getWidth + 'px', marginLeft: getMargin + '%'}"
       @resize="window.innerWidth > 900 ? windowMin=false : windowMin=true">
    <img class="icon" :src="require(`../../assets/icons/${icon}`)"/>
    <button class="LeftBarItemButton">{{getMsg}}</button>
  </div>
</template>

<style scoped>
  .LeftBarItem {
    background: transparent;
    color: #E6E8EF;
    border: #2B323E;
    border-radius: 20px;
    font-family: Andale-Mono;
    justify-content: left;
    display: flex;
    height: 60px;
    width: 244px;
    font-size: x-large;
    margin-bottom: 10px;
  }
  .LeftBarItem:hover {
    background: #222833;
    border-top: #1D222A 1px solid;
    border-left: #1D222A 1px solid;
    border-bottom: #1D222A 1px solid;
    border-right: #222833 1px solid;
    height: 58px;
    width: 242px;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .LeftBarItemButton {
    background: transparent;
    color: #E6E8EF;
    border: #2B323E;
    border-radius: 20px;
    font-family: Andale-Mono;
    font-size: x-large;
  }
  .icon {
    float: left;
    margin-right: 10px;
    margin-left: 5px;
    height: 35px;
    width: 35px;
    margin-top: 13px;
  }
</style>
